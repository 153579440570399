<template>
    <div
        class="content-main"
        :class="{ bg1: currentIndex == 1, bg2: currentIndex == 2, bg3: currentIndex == 0 }"
    >
        <div class="mask"></div>
        <!-- <img src="../assets/dameile-game.png" class="dameile-game" v-if="currentIndex == 0" />
        <img src="../assets/dekeshi-yuan.png" class="dameile-game2" v-if="currentIndex == 1" />-->
        <div class="arrows">
            <img src="../assets/icon-arrow-left.png" alt="上一页" @click="bannerGoBack()" />
            <img src="../assets/icon-arrow-right.png" alt="下一页" @click="bannerGoNext()" />
        </div>
        <div class="desc width460" v-if="currentIndex == 1">
            <img src="../assets/logo-1.png" />
            <div class="clearboth"></div>
            <p>达美乐行星是一个“元宇宙”概念的虚拟平台。</p>
            <p>以达美乐行星作载体，聚合流量、内容、商品和沉浸式体验等板块，</p>
            <p>叠加线上虚智能配送+AR比萨厨房和线下科技厨房体验，</p>
            <p>打造一个“元”的达美乐社区。</p>
            <p>在这里，玩家需要通过完成任务、互动、打卡等形式获得达金币，</p>
            <p>去达美乐集市进行兑换相应的权益，</p>
            <p>甚至是定制化数字产品。</p>
            <p>达美乐行星使得玩家在真实和虚拟之间0距离切换，</p>
            <p>获得沉浸式社交体验。</p>
            <div class="hr"></div>
        </div>

        <div class="desc width460" v-if="currentIndex == 2">
            <img src="../assets/logo-2.png" />
            <div class="clearboth"></div>
            <p>D星宇宙是一个“元宇宙”概念的虚拟平台。</p>
            <p>以D星作载体，聚合流量、内容、商品和沉浸式体验等板块，</p>
            <p>叠加线上AR农场技术和线下农场种植场景，打造一个“元”的D星社区。</p>
            <p>在D星宇宙中，玩家需要通过完成任务、种植、打卡等形式获得D金币，</p>
            <p>去D星集市进行兑换相应的权益、实物、甚至是定制化数字产品。</p>
            <p>D星宇宙分D星农场、D星娱乐、</p>
            <p>D星集市、D星直播、D星社区等一系列板块。</p>
            <div class="hr"></div>
        </div>

        <div class="desc width480" v-if="currentIndex == 0">
            <h1>公司介绍</h1>
            <h2>COMPANY INTRODUCTION</h2>
            <p>我们是一家成立于2017年，年轻的以创意为主导、</p>
            <p>技术为驱动的广告创意公司。</p>
            <p>我们更专注于对消费者洞察的精准把握，</p>
            <p>并擅长把洞察转化为鲜明的观点和付诸于优秀的创意内容及互动形式。</p>
            <p>我们的终极目标并不是为品牌打造杰出的广告作品，</p>
            <p>而是通过内容和技术实现品牌与用户之间的对话，</p>
            <p>进而影响消费者的思考和行为的模式，拉近他们之间的距离。</p>
            <p>我们也是一家技术驱动型的互动公司，始终坚信未来的创意是由技术驱动的，</p>
            <p>因此团队致力于将技术与内容做完美的结合，</p>
            <p>并通过覆盖顾客全生命周期的营销解决方案，</p>
            <p>定制化营销内容，让品牌构建属于商户自己的流量池。</p>
            <div class="hr"></div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    setup() {
        const banners = ref([
            '/images/dameile.png',
            '/images/dekeshi.png'
        ]);
        const currentIndex = ref(0);
        const bannerGoNext = () => {
            if (currentIndex.value >= 2) {
                currentIndex.value = 0;
            }
            else {
                currentIndex.value = currentIndex.value + 1;
            }

        }
        const bannerGoBack = () => {
            if (currentIndex.value == 0) {
                currentIndex.value = 2;
            }
            else {
                currentIndex.value = currentIndex.value - 1;
            }
        }
        return {
            banners,
            currentIndex,
            bannerGoNext,
            bannerGoBack

        };
    },
}
</script>

<style scoped>
.clearboth {
    clear: both;
}
.content-main {
    position: relative;
    width: 100%;
    height: 633px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
}

.mask {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
}

.bg1 {
    background-image: url("../assets/dameile.png");
}

.bg2 {
    background-image: url("../assets/dekeshi.png");
}

.bg3 {
    background-image: url("../assets/about.png");
}
.dameile-game {
    position: absolute;
    width: 180px;
    left: 100px;
    top: 80px;
    cursor: pointer;
}

.dameile-game2 {
    position: absolute;
    width: 180px;
    left: 100px;
    top: 80px;
    cursor: pointer;
}

.content-main .desc {
    position: absolute;
    right: 20px;
    top: 100px;
    padding-right: 10px;
}

.width460 {
    width: 480px;
}

.content-main .desc img {
    float: right;
    margin-bottom: 20px;
}

.desc p {
    color: #fff;
    font-size: 14px;
    text-align: right;
    line-height: 24px;
}
.hr {
    margin-top: 20px;
    width: 100%;
    height: 2px;
    background-color: #fff;
}
.arrows {
    position: absolute;
    bottom: 50px;
    right: 100px;
}
.arrows img {
    width: 40px;
    cursor: pointer;
}
.arrows img:first-child {
    margin-right: 60px;
}

h1 {
    font-size: 40px;
    color: #fff;
    text-align: right;
}
h2 {
    font-size: 20px;
    color: #fff;
    text-align: right;
    margin: 10px 0 20px 0;
}
</style>
